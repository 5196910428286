import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {

    constructor() { }

    // Set token to header for every http request
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = localStorage.getItem("token");
        if (authToken) {
            const headers = new HttpHeaders({
                'Authorization': "Bearer " + authToken,
            });
            const authRequest = req.clone({ headers });
            return next.handle(authRequest);
        }else{
            const headers = new HttpHeaders({
            });
            const authRequest = req.clone({ headers });
            return next.handle(authRequest);
        }
    }
}